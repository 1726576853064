@import '../css/variables';

.tooltip-row {
    text-align: start;
    font-size: 14px;
}

.table-headers {
  font-weight: 500;
}

.tooltip-inner {
    max-width: 300px;
    text-align: start;
}

.externalModal {
  .modal-dialog {
    max-width: 750px;
  }
}

.document-actions {
  align-items: center;
  display: flex;
  gap: 0.5em;
}

.badge {
  padding:4px 8px !important;
  margin:0 2px 0 0;
}

.quickstart-wrapper {
  .quickstart-container {
    background-color: $neutral-5;
    display: flex;
    gap: 1.325em;
    padding: 1.375em 2.0625em;
    min-width: fit-content;
    a, p {
      color: $neutral-70;
      text-decoration: none;
    }
    div {
      svg {
        height: 77px;
      }
      p {
        font-weight: 500;
        margin: 0;
      }
      &:hover {
        border: 2px solid $primary-2;
      }
      align-items: center;
      background: white;
      border: 1px solid $neutral-50;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      height: 146px;
      justify-content: center;
      width: 192px;
    }
  }
}
