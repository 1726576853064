#wizard-steps {
    --track-padding: 10px;
    display: flex;
    grid-auto-flow: column;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-behavior: smooth;
    padding: var(--track-padding);
    padding-bottom: calc(2 * var(--track-padding));
    margin: 0 calc(-1 * var(--track-padding));
    border-radius: 4px;

    .step-slide {
        --track-count: 5;
        @include sm {
            --track-count: 7;
        }
        @include md {
            --track-count: 10;
        }
        @include lg {
            --track-count: 13;
        }
        @include xl {
            --track-count: 15;
        }
        flex-shrink: 0;
        width: calc((100% + var(--track-padding)) / var(--track-count));
        position: relative;
        &::before {
            content: '';
            border-top: 1px solid $gray-300;
            position: absolute;
            width: 100%;
            height: 1px;
            top: 50%;
            z-index: -1;
        }
    }
}

#source-document-review-steps {
    --track-padding: 10px;
    display: flex;
    grid-auto-flow: column;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-behavior: smooth;
    padding: var(--track-padding);
    padding-bottom: calc(2.75 * var(--track-padding));
    margin: 0 calc(-1 * var(--track-padding));
    border-radius: 4px;
    justify-content: center;

    .step-slide {
        --track-count: 5;
        @include sm {
            --track-count: 7;
        }
        @include md {
            --track-count: 10;
        }
        @include lg {
            --track-count: 13;
        }
        @include xl {
            --track-count: 15;
        }
        flex-shrink: 0;
        width: 90px;
        position: relative;
        &::before {
            content: '';
            border-top: 1px solid $line-gray;
            position: absolute;
            width: 100%;
            height: 1px;
            top: 50%;
            z-index: -1;
        }
    }
}

