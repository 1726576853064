@use '../../css/App.scss' as *;

.documents-wrapper {
    min-height: 400px;
    .archive {
        float: right;
        margin: 0.25rem auto 0 0;
        font-size: 1rem;
    }
}

.documents-wrapper.dropping {
    border: 1px dashed rgb(72, 190, 166);
    opacity: 50%;
}

#document-list-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 15px;

    .set-dueDate-button{
        font-size: 12px;
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

#document-list-table th,
#document-list-table td {
    padding: 4px;
    margin: 0;
    text-align: left;
    border-bottom: 1px solid var(--line-gray);
    font-size: 12px;
}

#document-list-table td{
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.icon{
        width: 1em;
    }
}

#document-list-table td.actions-cell{
    overflow: unset;
    width:100px;
}

#document-list-table td.kebab-cell{
    overflow: unset;
}

#document-list-table td.name-cell{
    max-width: 200px;
}

#document-list-table td.date-cell{
    width: 80px;
}

#document-list-table th{
    -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

#document-list-table th.clickable{
    cursor: pointer;
}

#document-list-table th.sorting.descending{
    @include caret(down);
}

#document-list-table th.sorting.ascending{
    @include caret(up);
}

.archive-icon {
    color: $muted;
    cursor: pointer;
    font-size: 14px;
    &:hover {
        color: $primary;
    }
}

.doc-list-actions-dropdown 
{
    .dropdown-item {
        font-size: small;
    }

    .dropdown-menu{
        padding:0;
    }
}

.doc-list-actions-dropdown a::after {
    content: none;
}

.date-picker{
    width:210px;
}

div.add-x-days {
    margin-bottom: 16px;
    button {
        margin: 0px;
        padding: 0px;
        border: 0px;
        vertical-align:top;
    }
}
