@import './../../css/variables';

.pleadingSuccess {
  align-items: center;
  display: flex;
  flex-direction: column;
  p {
    font-size: 20px;
    &:last-child {
      font-size: 15px;
    }
  }
  svg {
    color: $dark-teal;
    height: 72px;
    margin: 15px;
    width: 66px;
  }
}
