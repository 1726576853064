@import '../../css/variables';

$toggle-radius: 20px;

p {
  &.customMenuLabel {
    margin: 0;

    svg {
      height: 14px;
      margin-right: 0.5em;
      width: 16px;
    }
  }
}

.caseSwitchToggle {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.caseMatched {
  input {
    padding-left: 2rem;
    background-image: url('../../images/logo.png');
    background-repeat: no-repeat;
    background-size: 0.9375rem;
    background-position: 0.6375rem;
  }
}

#case-client-confirm {
  select {
    font-size: 14px;
  }
}

.checkMatchContainer {
  display: flex;
  gap: 0.3125rem;
  margin: 0.625rem 0;

  p {
    font-size: 0.75rem;
    margin-bottom: 0;
  }

  svg {
    color: $feedback-warning-dark;

    &.match {
      color: $feedback-success-dark;
    }
  }
}

.external-partner-MyCase,
.external-partner-Clio,
.external-partner-Smokeball,
.external-partner-none {
  input {
    padding-left: 25px !important;
    background-repeat: no-repeat;
    background-position: 1.5% 50%;
    background-size: 15px;
  }
}

.external-partner-none {
  input {
    background-image: url('../../images/logo.svg');
  }
}

.external-partner-Smokeball {
  input {
    background-image: url('../../images/Smokeball_logo_mark.svg');
  }
}

.external-partner-MyCase {
  input {
    background-image: url('../../images/MyCase.svg');
  }
}

.external-partner-Clio {
  input {
    background-image: url('../../images/Glyph_Clio Glyph Blue.svg');
  }
}

.bpToggleTxtSwitch {
  border: 1px solid $neutral-50;
  border-radius: $toggle-radius;
  display: inline-block;
  font-weight: bold;

  label {
    background-color: transparent;
    border: 0;
    border-radius: $toggle-radius;
    color: $neutral-50;
    font-size: 0.874rem;
    margin-bottom: 0;
    padding: 5px 12px;

    &:hover {
      color: darken($neutral-50, 5%) !important;
      background-color: lighten($neutral-50, 40%) !important;
      border: 0;
    }

    &.btnChecked {
      background-color: $dark-teal !important;

      &:hover {
        color: white !important;
        background-color: $dark-teal !important;
        border: 0;
      }
    }
  }
}

/* For pendings UI flow */

.pendingSection {
  .pendingSectionHeader {
    background: #F2F3F3;
    border-bottom: 1px solid #B3B7Bb;
    color: $neutral-70;
    font-weight: 700;
    padding: 6px 16px;
    margin-top: 15px;

    p {
      margin-bottom: 0;
    }
  }

  .pendingSectionFields {
    padding-top: 13px;

    .allegationField {
      margin-bottom: 12px;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;
      &.noPadding {
        padding-left: 0;
        padding-right: 0;
      }
      .allegationFieldEdit, .pendingRemove {
        display: none;
      }
      &:hover {
        background-color: $neutral-5;
        .allegationFieldEdit, .pendingRemove {
          display: inline-block;
          cursor: pointer;
        }
      }
      label {
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }


    .pendingRemove {
      position: absolute;
      border: 0;
      background: transparent;
      top: 0;
      right: 0;
    }
  }
}

.caseTypeValid {
  > div {
    > div {
      border: 1px solid $red;
    }
  }
}

.swapButtonWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 11px;
  .swapButtonLine {
    flex: 1;
    height: 1px;
    background-color: #d2d5d5;
  }
  .swapButton {
    cursor: pointer;
    width: 24px;
    height: 24px;
    text-align: center;
    &.inActive {
      cursor: not-allowed;
      opacity: 0.65;
    }
  }
}
