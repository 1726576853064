@use '../../css/App.scss' as *;

.add-edit-case {
    label {
        font-weight:500;
        font-size:medium;
    }

    .modal-title {
        font-size: x-large;
    }

    .form-check{
        margin-bottom: 8px;
        label {
            font-weight: normal;
        }
    }

    #attorneys-container {
        padding-top: 23px;
        border-top: 1px solid #CCCCCC;
        clear:both;
        h3 {
            font-weight: 500;
        }
        .row {
            min-height: 30px;
        }
        .btn-link {
            margin: auto;
            padding: 0px;
        }
        .add-attorneys{
            margin-top: 8px;
        }
    }

    .client-input-row {
        margin-bottom: 24px;
        margin-right: 24px;
        width: 45%;
        float: left;

        &.full{
            width: 94%;
        }

        label.required:before {
            content:"*";
            color:$red;
          }
          .checkbox-container{
            span{
                display: inline-block;
                margin-right: 12px;
                min-width: 100px;
            }
        }
    }

    .full {
        width:100%;
        float: left;
    }

    h4 {
        clear: both;
    }

    .table-edit-item {
        cursor: pointer;
    }

    p .btn-link{
        margin: auto;
        padding: 0px;
        font-size: small;
        line-height: normal;
        border: 0px;
    }

    .definitions-container {
        max-height: 900px;
        overflow-y: scroll;
    }
}

.email-error-span{
    display: inline-flex;
     align-items: center;
     color: #BC3F34;
  }