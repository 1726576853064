.page-container-multi {
    .tooltip-row {
        text-align: start;
        font-size: 14px;
    }

    .tooltip-inner {
        max-width: 300px;
        text-align: start;
    }



    .filter-label {
        font-weight: 600;
        position: absolute;
        margin-top: -18px;
        margin-left: 8px;
    }

    .class-details-wrapper-multi {
        margin: 0px;
        margin-bottom: 18px;
        border-radius: 4px;
        border: 1px solid #CCCFCF;
        width: 100%;

        .case-details-title-wrapper {
            margin: auto;
            padding: 0px;

            .case-details-title {
                background-color: #F2F3F3;
                padding: 15px;

                font-weight: bold;
                font-size: large;

                .sourceLogo {
                    svg {
                        height: 14px;
                        width: 14px;
                        margin-right: 0.25em;
                    }
                }
            }
        }

        .case-headers {
            font-weight: bold;
        }

        .case-details {
            font-size: 12px;
            margin: auto;
            padding: 15px 3px;

            .row .col {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
            }
        }

        .tag-pill {
            background-color: #E6E7E7;
            border-radius: 4px;
            padding: 2.5px 8px;
            margin-right: 8px;
        }

        .close-case-btn {
            font-size: small;
            padding: 0;
            color: #bc3f34;
            margin-left: 8px;
        }
    }

    .table-actions-row {
        justify-content: flex-end;

        .col {
            padding-right: 0px;
        }

        .btn.btn-outline-primary {
            &.show {
                color: #E6E7E7;
            }
        }
    }
}