@use '../../css/App.scss' as *;

.add-edit-client {
    label {
        font-weight:500;
        font-size:medium;
    }

    .modal-title {
        font-size: x-large;
    }

    #attorneys-container {
        padding-top: 23px;
        border-top: 1px solid #CCCCCC;
        clear:both;
        h3 {
            font-weight: 500;
        }
        .row {
            min-height: 30px;
        }
        .btn-link {
            margin: auto;
            padding: 0px;
        }
        .add-attorneys{
            margin-top: 8px;
        }
    }

    .client-input-row {
        margin-bottom: 24px;
        margin-right: 24px;
        width: 45%;
        float: left;

        label {
          &:first-child {
            font-weight: bold;
          }
        }

        #lng-selection {
          .form-check-inline {
              margin-right: 50px;
              &:last-child {
                margin-right: 0;
              }
            }
        }

        &.full{
            width: 94%;
        }
        .text-muted 
        {
            padding-left: .15rem !important;
            svg{
            vertical-align:text-top;
        }
        }

        label.required:after {
            content:"*";
            color:$red;
          }
    }

    h4 {
        clear: both;
    }

    .table-edit-item {
        cursor: pointer;
    }
}
