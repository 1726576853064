#empty-document-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed var(--gray-500);
    border-radius: 6px;
    padding: 57px 0 103px;
    max-width: 890px;
    margin: auto;
}
#empty-document-list.dropping {
    border: 1px solid var(--gray-500);
    opacity: 50%;
}

#empty-document-list img {
    width: 23.69rem;
    margin-bottom: 8px;
}

#empty-document-list h3 {
    margin: 8px 0;
    font-size: 20px;
    font-weight: normal;
}

#empty-document-list ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    list-style: inside;
    padding:0;
    font-size: 14px;
}

#empty-document-list p {
    margin-bottom: 1rem;
}

#empty-document-list .smaller {
    margin-bottom: .1rem;
}
