$primary-color: #044154;
$secondary-color: #FFFFFF;

.confirm-client-info {
  display: flex;
  flex-direction: column;
  max-width: 35rem;

  input {
    border-radius: 4px;
    &[readonly] {
      background-color: #f0f0f0;
    }
  }

  p {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }

  .button-container {
    margin-left: auto;
    padding: 4px;
    margin-top: 0px;

    .button:active {
      transform: scale(0.95);
    }

    .button {
      margin: 4px;
      margin-right: 0px;

      &.secondary {
        color: $primary-color;
        background: $secondary-color;
      }
    }
  }
}

.email-container {
    display: flex;
    flex-direction: column;
    max-width: 45rem;
  
    hr {
      margin-bottom: 1rem;
    }
  
    h2 {
      margin-bottom: 1rem;
    }
  
    .note {
      display: flex;
      min-height: 78px;
      flex-direction: row;
      border: 1px solid $primary-color;
      align-items: center;
      border-left: 8px solid $primary-color;
      padding: 4px;
      padding-left: 8px;
      background: #EDFBFF;
      border-radius: 8px;
  
      .note-content {
        display: flex;
  
        .flag-icon {
          min-width: 18px;
          min-height: 18px;
          margin-top: 6px;
          margin-right: 4px;
        }
      }
    }
  
    .button-group {
      margin-right: auto;
      padding-top: 0px;
      padding-bottom: 1rem;
  
      .copy-button:active {
        transform: scale(0.95);
      }

      .copy-button {
        margin: 4px;
        margin-left: 0;
  
        .check-icon, .copy-icon {
          margin-right: 4px;
          margin-bottom: 4px;
        }

        .copy-icon {
          transform: rotate(90deg);
        }
      }
  
      .compose-button {
        margin: 4px;
        background: $secondary-color;
        color: $primary-color;
  
        .compose-button:active {
          transform: scale(0.95);
        }

        .pencil-icon {
          margin-right: 4px;
          margin-bottom: 4px;
          color: $primary-color;
        }
      }
    }
  
    .email-container {
      display: flex;
      flex-direction: column;
      border: 1px solid $primary-color;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
      padding-top: 12px;

      .header {
        display: flex;
        justify-content: flex-end;
        background: #F2F3F3;
        min-height: 42px;
        width: auto;
        margin: -4px;
        margin-bottom: 4px;
        margin-left: -16px;
        margin-right: -16px;
        margin-top: -12px;
  
        .save-button:active {
          transform: scale(0.95);
        }

        .save-button {
          margin-top: 8px;
          margin-bottom: 8px;
          margin-right: 16px;
          background: $secondary-color;
          color: #343F40;
        }
      }
  
      .subject {
        margin: 4px;
        display: flex;
        align-items: center;
  
        input {
          flex: 1;
          margin-left: 8px;
          margin-right: -4px;
        }
      }
    }
    
    .return-button:active {
      transform: scale(0.95);
    }
    .return-button {
      margin-left: auto;
      margin-top: 16px;
    }
  }
