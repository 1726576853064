@use 'sass:math';
@import '../../css/variables';

$height: 688;
$width: 558.93;
$left-padding: 39;

.confirm-information-container {
    border: 1px solid $primary-2;
    border-radius: 8px;
    padding: 1.5rem 3.8125rem;
    h3 {
        margin-bottom: 1rem;
    }

    .btn-link{
        padding-top: 0px;
        padding-bottom: 0px;
    }

   #case-client-confirm .label,label{
    font-weight: 600;
   } 
}

.interrogatory-text {
    display: flex;
    position: relative;
    padding: 0px 2rem 0px 0px;
    .edit-interrogatory-item {
        font-size: 0.875em;
        color: #585e60;
        cursor: pointer;
        position: absolute;
        right: 10px;
        display: none;
    }
    &:hover {
        background-color: $ocean-foam;
        .edit-interrogatory-item {
            display: block;
        }
    }
}

.list-column {
    overflow-y:scroll;
    height: 800px;
}

.interrogatory-list-wrapper {
    padding: 1.5rem;
    padding-right: 3rem;

    .interrogatories-edit {
        .selection-item {
            &.isDragging {
                background-color: $ocean-foam;
                .drag-selection-item {
                    visibility: visible;
                }
            }

            .selected {
                background-color: $ocean-foam;
                color: $primary;
                padding-bottom: 20px;
            }

            .interrogatory-item {
                .main-item {
                    margin-right: 15px;
                }
                .adding-item {
                    padding-bottom: 15px;
                }
                .drag-selection-item {
                    visibility: hidden;
                    margin-left: 10px;
                    margin-right: 10px;
                }

                &:hover {
                    background-color: $ocean-foam;
                    color: $primary;
                    .drag-selection-item {
                        visibility: visible;
                    }
                }

            }

            .button-wrapper {
                background-color: white;
            }
        }
    }

    .hide-item {
        display:none;
    }
    .add-item-wrapper {
        text-align: center;
        padding: 5px;
        margin-top: 20px;
        position: relative;
        background-color: white;
    }
    .border-line {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        position: relative;
    }
    .add-item-wrapper:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        border-top: 1px solid $dark-teal;
        background: black;
        width: 100%;
        transform: translateY(-50%);
    }

    .interrogatory-title {
        font-size: 1rem;
        margin-left: 35px;
    }

    .new-item-form {
        height: 100px;
        padding-bottom: 10px;
    }

    .remove-item {
        font-size: 1.25rem;
        color: $secondary;
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }
}

.source-document-information-container {
    width: 422px;
}

.document-outline-wrapper {
    max-width: 100%;
    border: 1px solid $border-color;
    border-radius: 5px;
}

.document-outline {
    width: 100%;
    padding-left: percentage(math.div($left-padding, $width));

    .active-line {
        background-color: $primary-light;
        height: 3px;
        top: 50%;
        right: -25px;
    }

    .section {
        border-radius: 4px;
        background-color: $light;

        &.active {
            background-color: $primary-light;
        }

        &.confirmed {
            background-color: $ocean-foam;
        }

        &.info,
        &.info-bottom,
        &.venue {
            width: percentage(math.div(266, $width));
        }

        &.info {
            margin-top: percentage(math.div(53, $height));
            margin-bottom: percentage(math.div(9, $height));
        }

        &.info div {
            padding-bottom: percentage(math.div(163, 266));
        }

        &.info-bottom {
            margin-bottom: percentage(math.div(22.45, $height));
        }

        &.info-bottom div {
            padding-bottom: percentage(math.div(33, 266));
        }

        &.venue {
            margin-left: percentage(math.div(156 - $left-padding, $width));
            margin-bottom: percentage(math.div(21.13, $height));
        }

        &.venue div {
            padding-bottom: percentage(math.div(24, 266));
        }

        &.plaintiff-name,
        &.defendant-name {
            margin-bottom: 3%;
        }

        &.plaintiff-name div,
        &.subtype div {
            padding-bottom: percentage(math.div(63, 224));
        }

        &.defendant-name div {
            padding-bottom: 58%;
        }

        &.plaintiff-name,
        &.defendant-name,
        &.subtype {
            width: percentage(math.div(224, math.div($width - $left-padding, 2)));
        }

        &.plaintiff,
        &.defendant {
            margin-left: percentage(math.div(91.5 - $left-padding, math.div($width - $left-padding, 2)));
            margin-bottom: 10%;
        }

        &.defendant {
            margin-bottom: 15%;
        }

        &.plaintiff,
        &.defendant,
        &.type,
        &.case {
            width: percentage(math.div(129, math.div($width - $left-padding, 2)));
        }

        &.plaintiff div,
        &.defendant div {
            padding-bottom: percentage(math.div(14, 129));
        }

        &.type,
        &.case {
            margin-bottom: 3%;
        }

        &.case div {
            padding-bottom: percentage(math.div(18, 129));
        }

        &.type div {
            padding-bottom: percentage(math.div(18, 129));
        }

        div {
            position: relative;
        }

        @mixin checkmark($dimension) {
            top: calc((100% - #{$dimension}) / 2);
            width: $dimension;
            height: $dimension;
        }

        svg {
            color: $success;
            position: absolute;
            right: 3px;
            @include checkmark(9px);
            @include md {
                @include checkmark(12px);
            }
            @include lg {
                @include checkmark(20px);
            }
        }
    }
}
