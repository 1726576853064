@use '../../css/App.scss' as *;

#attorneys-container {
    padding-top: 23px;
    border-top: 1px solid #CCCCCC;
    
    .attorney-select.row {
        .col {
            border-bottom: 1px solid #CCCCCC;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .initials-avatar {
            background-color: $primary;
        }
    }

    .attorney-select .set-primary svg {
        cursor: pointer;
        fill: $primary-2;
    }

    .top-level.row {
        .col {
            border-bottom: 1px solid #CCCCCC;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .initials-avatar {
        cursor: auto;
        width: 30px;
        height: 30px;
        font-weight: 500;
        font-size: 13px;
        margin-right: 12px;
    }

    .headers {
        font-weight: 600;
        font-size: small;
        margin-top: 16px;
        .col {
            border-bottom: 1px solid #CCCCCC;
            padding-bottom: 5px;
        }
    }
    h3 {
        font-weight: 500;
    }
    
    .btn-link {
        margin: auto;
        padding: 0px;
    }
    .add-attorneys{
        margin-top: 8px;
    }
}