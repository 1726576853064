.invite-modal-content {
    label {
        font-weight:500;
        font-size:medium;
    }

    .email-instruction {
        margin-bottom: 24px;
    }

    .modal-title {
        font-size: x-large;
    }

    .modal-instructions {
        margin-bottom: 16px;
    }

    .form-check {
        margin-bottom: 8px;
        label {
            font-weight: normal;
        }

        &.checkbox-user-type-paralegal {
            margin-right: 0;
        }
    }
}