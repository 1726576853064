.btn.wide {
    min-width: rem(99);
}

.btn.btn-outline-primary {
    &:not(:hover) {
        color: $text;
    }
}

.btn.btn-outline-primary.fill-white {
    &:not(:hover) {
        background-color: white;
    }
}

.btn.btn-sm {
    padding: 7px 15px;
}

.btn-hover-bg-fix:hover {
    --bs-btn-hover-bg: #044154 !important;
}