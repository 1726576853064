@import "./../../../../css/variables";

.checkIconFeedback {
  position: absolute;
  right: 6%;
  top: 11px;
  svg {
    color: $primary-2;
    width: 24px;
    height: 24px;
  }
}

.savedFeedback {
  background-color: $primary-2;
  border-radius: 0.25rem;
  color: white;
  display: inline-block;
  padding: 0.25rem 0.5rem;
}

.selections-panel {
    margin: 0 0 0 -25px;
    padding-left: 0;
    .selection-item {
        ol, ul {
            margin-left: 8ch;
            padding:0;
            li div {
                text-indent: 0;
            }
        }
        .tiptap{
            ol, ul{
                margin-left: 16px;
            }
        }
        &.isDragging {
            background-color: $ocean-foam;
            .drag-selection-item {
                visibility: visible;
            }
        }
        &.is-called-out {
            background-color: $ocean-foam;
        }
        .edit-selection-item {
            font-size: 0.875em;
            color: $secondary;
            cursor: pointer;
            &:hover {
                color: $primary;
            }
            position: absolute;
            top: 11px;
            right: 10px;
            display: none;
            &.bmd {
                top: 32px;
            }
            &.disabled {
                cursor: wait;
            }
        }

        .hidden{
            display: none;
        }

        .edit-all-button {
            text-align: end;
        }

        .objection-content{
            min-height: 48px;
            transition: all ease-out 1s;
            .bespoked{
                background-color: #F5F5F5; 
            }
        }

        .bespoking-placeholder {
            cursor:progress;
            position:absolute;
            top:0px;
            left:0px;
            height: 100%;
            width:100%;
            padding: 7px 0;
            text-align: center;
            background-color: $ocean-foam;
            font-family: var(--bs-body-font-family);
            min-height:48px;
            user-select: none;
            display:none;

            &.show {
                display: block;
                cursor:auto;
            }

            .inner {
                position: absolute;
                top: 30%;
                width: 100%;
            }
        }

        .bespoked-response{
            position:absolute;
            display: block;
            background: white;
            top: 0px;
            left: 100%;
            border: 1px solid $primary;
            border-left: 8px solid $primary;
            font-family: var(--bs-body-font-family);
            font-size: 12px;
            border-radius: 4px;
            overflow: hidden;
            transition: all ease-out 1s;
            max-width: 200px;
            height: 60px;
            padding: 6px 8px;
            cursor: auto;
            z-index: 1;
            &.closed {
                max-width: 0px;
                opacity: 0;
            } 

            .content {
                width:150px;
            }

            img {
                padding-bottom: 4px;
            }

            svg {
                cursor: pointer;
                padding-bottom: 4px;
                &:hover{
                    color:$primary;
                }
            }
        }
    }
    @at-root ul#{&} {
        list-style: none;
        .selection-item {
            margin: 0px -7px;
            padding: 7px 2rem 7px 3.8rem;
            position: relative;
            border-radius: 2px;
            &:hover {
                background-color: $ocean-foam;
                .edit-selection-item {
                    display: block;
                }
                .drag-selection-item {
                    visibility: visible;
                }
            }
            .drag-selection-item {
                position: absolute;
                top: 12px;
                left: 12px;
                visibility: hidden;
            }
            .dash-selection-item {
                position: absolute;
                top: 6px;
                left: 38px;
            }
        }
        .selection-actions {
            display: none;
            position: absolute;
            top: 5px;
            right: 7px;
            z-index: 5;
            .dropdown-toggle {
                background-color: white;
                color: $primary;
            }
        }
    }
    @at-root p#{&} {
        font-family: var(--bs-font-serif);
        text-indent: 8ch;
        .selection-item {
            display: inline;
            &::after {
                content: ' ';
            }
        }
    }

    pre {
        font-family: var(--bs-font-serif);
        text-indent: 8ch;
        white-space: "pre-wrap";
        display: 'block';
    }

    .form-int-label {
        font-size: 1rem;
    }

    .multiple-response-section {
        position: relative;

        .btn-close {
            position: absolute;
            top: 3px;
            right: 0;
        }
    }
}
