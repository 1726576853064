.toggle-container {
    display: flex;
  }
  
  .toggle {
    display: flex;
    align-items: center;
    gap: 8px;
   // color: gray;
   svg {
     width: 14px;
   }
  
    .pill {
      width: 36px;
      height: 20px;
      background-color: #B3B7B7;
      border-radius: 20px;
      &.active-pill {
        background-color: var(--primary-2);
      }
      &.toggle-disabled {
        cursor: not-allowed;
      }
      .pip {
        height: 16px;
        width: 16px;
        background-color: white;
        border-radius: 8px;
        position: relative;
        top: 2px;
        left: 2px;
        transition: .2s;
        transition-timing-function: ease-out;
        &.active-pip {
          left: 18px;
          transition: .2s;
          transition-timing-function: ease-out;
        }
      }
    }
    .inactive-status {
      color: black;
    }
    .active-status {
      color: var(--primary-2);
    }
  }
