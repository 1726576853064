.client-activity-row-container {
  padding: 3em;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #F2F3F3;
  margin-bottom: -4px;
  margin-left: -4px;
  margin-right: -4px;
}

.client-activity-header {
  margin-bottom: 4px;
}

.client-activity-hr {
  margin-top: 0px;
  margin-bottom: 4px;
}

.client-activity-content {
  display: flex;
  overflow-x: auto;
}

.client-activity-table {
  th {
    padding-right: 3em;
  }

  .client-activity-tr {
    border: hidden;
  }
}

.remove-client-access-btn,
.view-link-code-btn {
  margin: 4px !important;
  font-size: 1em !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.bi-chevron-down {
  cursor: pointer;
}

.remove-client-access-btn {
  background: #FFFFFF !important;
  color: #044154 !important;

  &:hover {
    background: #044154 !important;
    color: #FFFFFF !important;
  }
}

.activity-reminder-wrapper {
  margin: 0px;
  display: flex;
  flex-direction: row;
  letter-spacing: -0.2px;
}

.activity-reminder-txt {
  cursor: pointer;
  margin-left: 2px;
  text-decoration: underline;
  color: #044154
}

.highlightRow {
  animation: pulse-animation 1s ease-in-out;

  @keyframes pulse-animation {
    0% {
      background-color: transparent;
    }

    50% {
      background-color: #E6F8F1;
    }

    0% {
      background-color: transparent;
    }
  }
}