@import '../../css/variables';

.noMargins {
  margin-top: 0;
  margin-bottom: 0;
}

.cleanImgBorder {
  border: none !important;
  box-shadow: none !important;
}

.noHeaderBorders {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  padding: 0.35rem;
}

.hightlight {
  display: inline-block;
  font-weight: bold;
  padding: 0.35rem 0.65rem;
  &.docType {
    background-color: $light-teal;
  }
  &.caseType {
    background-color: $pale-yellow;
  }
  &.rpcaseType {
    background-color: $pale-green;
  }
}
