.filter-div-gp {
    justify-content: flex-end;
    align-items: flex-start; 
    margin-top: 5px;
}


.form-check{
    input,label {
        cursor: pointer;
    }
}

.step-container {
    .clear-btn {
        padding-left: 7px;
        font-size: 12px;
    }

    .filter-label {
        font-weight: 700;
        position: absolute;
        margin-top: -18px;
        margin-left: 8px;
    }

    .filter-title {
        font-weight: 700;
    }

    .filters {
        position: relative;
        font-size: 12px !important;
        .btn {
        font-size: 12px;
        }
    }
    
    .expand-all {
        font-size: 12px;
    }

    .table-row-main {
        &:hover {
            background-color: #F1F3F3;
        }

        .actions-cell{
            svg {
                cursor: pointer;
                font-size: 15px;
            }
        }

        .question-from{
            svg {
                height: 15px;
                width:15px
            }
            width:15px;
        }
    }

    .table-row {
        max-width: 90%;
        &:hover{
            background-color: white !important;
        }
        ol{
            margin-bottom: 8px;
        }
    }
}