@import '../../css/variables';

.table-left {
  max-width: 16rem;
  display: flex;
  align-items: center;
  padding-right: 2.5rem;

  span {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon-container {
    display: flex;
    align-items: center;
  }
}

.table-right {
  font-size: 12px;
}

.bi-file-earmark {
  cursor: pointer;
}

svg {
  &.checkCopied {
    color: $primary-2;
  }
}