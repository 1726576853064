.party-select-modal{
    .client-input-row {
        width: 90%;
        float: left;
        
        label.padding {
            margin-top: 16px;
        }

        label.required:before {
            content:"*";
            color:var(--danger);
        }
        .checkbox-container{
            span{
                display: inline-block;
                margin-right: 12px;
                min-width: 100px;
            }
        }
    }

    .note {
        font-size: small;
    }
}