@import "../../css/variables";

.onbehalfof-tile-content {
    border: 1px solid $text;
    border-radius: 4px;
    font-size: 21px;
    cursor: pointer;
    margin:1px;

    .initials-avatar {
        background-color: $primary;
        width: 56px;
        height: 56px;
        font-weight: 500;
        font-size: 22px;
        margin: 12px;
    }

    &:hover {
        border:2px solid $primary-2;
        margin:0px;
    }

    .selected-icon {
        color: $primary-2;
        font-size: 42px;
        float: right;
        margin-right: 14px;
    }
}