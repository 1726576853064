.quickAccBtnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
}

.quickActionContainer {
  .quickActionRadio {
    min-height: 40px;
    margin-bottom: 15px;
  }
  label {
    &.quickActionLabels {
      font-weight: bold;
    }
  }
}

.quickAccInput {
  margin-bottom: 0.75rem;

  .rbt-aux {
    position: absolute !important;
    right: 1%;
    top: 24%;
  }
}

.external-partner-MyCase, .external-partner-Clio, .external-partner-Smokeball, .external-partner-none {
  input {
    padding-left: 30px !important;
    background-repeat: no-repeat;
    background-position: 1.5% 50%;
    background-size: 15px;
  }
} 

.external-partner-none {
  input {
    background-image: url('../../images/logo.svg');
  }
}

.external-partner-Smokeball {
  input {
      background-image: url('../../images/Smokeball_logo_mark.svg');
  }
}

.external-partner-MyCase {
  input {
      background-image: url('../../images/MyCase.svg');
  }
}

.external-partner-Clio {
  input {
    background-image: url('../../images/Glyph_Clio Glyph Blue.svg');
  }
}
