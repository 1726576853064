// LEARN ABOUT BOOTSTRAP CUSTOMIZATION HERE:
// https://getbootstrap.com/docs/5.0/customize/sass/

/* GET FUNCTIONS */
@import '~bootstrap/scss/functions';

/* OVERRIDE DEFAULT VARIABLES */
@import '_variables';

/* GET REQUIRED BOOTSTRAP */
@import '~bootstrap/scss/variables';
@import "~bootstrap/scss/maps";
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

/* MODIFY SCSS MAPS */
@import '_extensions';

/* OPTIONAL BOOTSTRAP - IMPORT AS NEEDED */
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/badge';

/* DEFINE EXTRA GLOBAL CLASSES */
@import '_mixins';
@import '_buttons';
@import '_modals';
@import '_select';

/* COMPONENT STYLES - Import here instead of in componenet to use SCSS variables and functions. */
@import '../components/DocumentList/ProcessingBox';
@import '../components/DocumentList/DocumentList';
@import '../components/Wizard/WizardDocumentReview';
@import '../components/ConfirmInfo/DocumentOutline';
@import '../components/Wizard/WizardSteps.scss';
@import '../components/Wizard/AnswerWizard.scss';
@import '../components/Wizard/AnswerWizard.scss';
@import '../components/Wizard/styles.scss';
@import '../screens/DocumentWizard/SpecialInterrogatoriesStep/Selections/Selections.scss';
@import '../screens/Library/Library.scss';
@import '../screens/HowTo/HowTo.scss';

/* AUTO GENERATE CLASSES */
@import '../../node_modules/bootstrap/scss/utilities/api';

// GLOBAL STYLES THAT DON'T DESERVE THEIR OWN FILE
html,
body {
    display: flex;
    flex-direction: column;

    &.modal-open {
        div[data-floating-ui-portal] {
            /* Needed to show Popup components when used in modals */
            z-index: 1100;
        }
    }
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

nav {
    box-shadow: 0 2px 4px 0 rgba(203, 199, 199, 0.5);
    flex-shrink: 0;
    background-color: $white;
}

main {
    flex: 1;
    overflow: auto;

    .page-container {
        padding-top: rem(16);
        padding-bottom: rem(16);
    }
}

.font-times {
    font-family: 'Times New Roman', Times, serif;
}

.cursor-pointer {
    cursor: pointer;
}

.toast-container {
    position: fixed !important;
    top: 80px !important;
}

/*
Font Sizes
xs (12px): .fs-7, .h7
sm (14px): .fs-6, .small, .h6
md (16px, base): .fs-5, .h5
lg (18px): .fs-4, .h4
xl (20px): .fs-3, .h3
xxl (22px): .fs-2, .h2
xxxl (28px): .fs-1, .h1
*/

.logo {
    width: 175px;
}

.primary-highlight {
    padding: 0.2em;
    background-color: #d6eae4;
}

.green-highlight {
    padding: 0.2em;
    background-color: #d8edcf;
}

.break-margin {
    margin: 2rem 0;
}

.btn-30 {
    padding: 7px 7.5px 5px 13.5px;
    max-height: 30px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
}

.btn-26 {
    max-height: 26px;
    padding-top: 3px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
}

.defense-search-input {
    border-left: none;
    font-size: 0.875rem;
    padding: 0px;
    height: 26px;
    padding-left: 10px;
    border-radius: 0rem 0.25rem 0.25rem 0rem !important;

    &:focus {
        box-shadow: none;
    }
}

.input-group-search {
    &:focus-within {
        outline: none;
        border-radius: 0.25rem;
        box-shadow: 0 0 0 0.2rem #C0CFD4;
    }
}

.how-to-container {
    max-width: 1135px;

    h1,
    h2,
    h3 {
        font-weight: 500;
    }

    h1 {
        margin-bottom: 24px;
        font-size: 2.25rem;
    }

    h2,
    h3 {
        font-size: 1.75rem;
        margin-bottom: 8px;

        &.subtitle {
            font-size: 24px;
        }
    }

    p {
        font-size: 1.125rem;
        max-width: 745px;

        &.maxWidth {
            max-width: none;
        }
    }

    .help-box {
        font-size: 12px;
        padding: 5px;
        box-sizing: border-box;
        height: 20px;
        width: 117px;
        border: 1px solid #979797;
        border-radius: 4px;
    }

    .help-table {
        border-collapse: collapse;
        width: 100%;

        .line-height-double {
            line-height: 48px;
        }

        .dark-background {
            background-color: #F2F2F2;
        }

        .table-padding {
            padding: 8px;
        }

        .fixedWidth {
            width: 17%;
        }

        td,
        th {
            border: 1px solid #dddddd;
            text-align: left;
        }
    }
}

.alert-warning {
    border-left: 16px solid $warning;
}

.alert-info {
    border-left: 16px solid $primary;
}

.rbt-close-content {
    color: white;
    opacity: 0;
}

.form-control:disabled {
    background-color: #F2F3F3;
}

.onbehalfof-banner {
    background-color: $pale-green;
    color: $primary;
    border-bottom: 1px solid $primary;
    line-height: 32px;
    text-align: center;
    font-size: 13px;

    button {
        margin: 0px;
        border: 0px;
        padding: 0px 0px 3px 0px;
        font-size: 13px;
    }
}

.navbar {
    @include sm {
        padding: 0;
    }

    .notifications {
        position: relative;
        color: white;

        .nav-link {
            cursor: pointer;
        }

        .highlight:after {
            content: "";
            display: block;
            position: absolute;
            width: 8px;
            height: 8px;
            background: $red;
            top: 35%;
            right: 20%;
            border-radius: 50%;
        }

        .content {
            display: none;
            color: $text;
            width: 300px;
            border-radius: 4px;
            border: 1px solid $gray-200;
            right: 0;
            top: 80%;
            background-color: white;
            padding-top: 8px;
            padding-bottom: 8px;

            &.show {
                display: block;
                position: absolute;
            }

            h3,
            h4,
            p {
                padding-left: 22px;
                padding-right: 22px;
                padding-bottom: 4px;
            }

            h3 {
                font-size: 18px;
                font-weight: 700;
            }

            .item {
                border-top: 1pt solid $gray-200;
                padding-top: 8px;

                h4 {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 4px;
                    padding-bottom: 0px;
                }

                .date {
                    font-size: 10px;
                    font-weight: 400;
                    margin-bottom: 0px;
                }

                .text {
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 0px;
                }

                &.blank {
                    border-top: none;
                }
            }
        }
    }
}

.nav-link {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;

    &.active {
        @include sm {
            padding-bottom: 4px;
            border-bottom: 4px solid $gray-500;
        }
    }
}