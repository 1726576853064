.generate-request-container {
    font-family: Helvetica;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .header-container {
        margin-top:16px;
        margin-bottom: 8px;
        line-height: 36px;
        display: flex;
        flex-direction: row;
    }

    .header-span {
        text-decoration: underline;
    }

    .request-header {
        color: var(--neutral-70);
        margin: 0;
        margin-right: 10px;
        .info-link{
            font-size: 16px;
            vertical-align: top;
        }
    }

    .request-row {
        display: flex;
        flex-direction: row;
    }

    .position-check-col {
        width: 40% !important;
    }

    .accordion-body-container>div {
        padding: 5px 2px;
    }

    .accordion-main {
        width: 100%;
    }

    .section-header {
        display: flex;
        flex-direction: row;
        margin-bottom: 0px;
        color: var(--neutral-70);
        background-color: var(--neutral-5);
        border-bottom: 1px solid var(--gray-300);
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        button {
            width: 100%;
            color: var(--neutral-70);
            padding:0;
            background-color: var(--neutral-5);
            text-align: left;
            border: none;
        }
    }

    .section-body{
        padding:0 24px 16px;
        .questions{
            margin-top: 12px;
        }
    }

    .accordion-body {
        ol {
            padding-left: 1rem;
        }
    }

    h2>button {
        background-color: var(--neutral-5);
        text-align: left;
        border: none;
        padding: 5px;
    }

    h2>button:hover {
        background-color: var(--neutral-5);
    }

    .details-span {
        padding-left: 15px;
        padding-top: 5px;
        text-decoration: none;
    }

    .arrow-span {
        float: right;
        padding-right: 15px;
    }

    .questions-border-box {
        border: 1px solid black;
        border-radius: 5px;
    }

    .accordion-item-header {
        margin-top: 10px;
        font-weight: 700;
    }

    .definitions-header {
        margin-top: 10px;
        font-weight: 700;
    }

    .accordion-lower-item-header {
        margin-top: 18px;
        font-weight: 700;
    }

    .check-group-wrapper {
        margin-top: 10px;
    }

    .check-wrapper {
        margin-top: 3px;
    }

    .page-container {
        margin-bottom: 20px;
    }

    .def-check-wrapper {
        margin-top: 10px;
        margin-left: 5px;
    }

    .group-title-container {
        height: 25px;
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--neutral-70);
        font-size: 16px;
        color: var(--neutral-70);

        h3 {
            font-size: 16px;
        }

        button {
            border: 0px;
            padding: 0px;
            margin-left: 16px;
            font-size: 12px;
            margin-top: 3pt;
        }

        .bp-defaults-added{
            font-size: 12px;
            margin-left: 16px;
            user-select: none;
        }
    }

    .input-wrapper {
        width: 100%;
        .indent-radio{
            margin-left: 20px;
        }
    }

    .question-item {
        border: 1px solid #CCCFCF;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-size: 14px; 
        margin-bottom: 8px;
        min-height: 30px;
        padding: 16px 16px 16px 12px;
        -webkit-user-select: none; 
        user-select: none; 
        color: #808788 !important;
        &.selected {
          color: #4D5758 !important;
        }
            &:hover {
                background-color: var(--ocean-foam);
                .edit-selection-item {
                    display: block;
                }
            }

        .question-item-check-wrapper {
            display: flex;
            width: 20px;
            margin-top: -2px;
        }

        .question-item-number {
            display: flex;
            flex-direction: column;
            margin-left: 5px;
            min-width: 28px;
        }

        .question-item-content {
            display: flex;
            width: 100%;
            position: relative;
            margin-left: 5px;
            padding: 0px 1rem 0px 0rem;

            &>div {
                display: flex;
                vertical-align: top;
                width: 100%;

                //visibility: hidden;
                textarea {
                    width: 100%;
                }
            }

            .question-text{
                display: block;
                .definition-match {
                    text-decoration: underline;
                    cursor:help;
                    display: inline;
                }
            }


            &:hover>div {
                visibility: visible;
            }
        
        &.nohov {
            &:hover {
                background-color: white;
                .edit-selection-item {
                    display: none;
                }
            }
        }
    }
    }

    .edit-input-container {
        &>div {
            width: 100%;

            textarea {
                width: 100%;
            }
        }

        &>textarea {
            width: 100%;
        }
    }

    .definitions-container {
        ol {
            margin: 0px;
            padding-left: 12px;
            li::marker{
                font-size: 16px;
            }

            .col {
                position: relative;
                .alias-help{
                    position: absolute;
                    font-size: small;
                }
            }
        }
    }

    label {
        font-weight: 700;
        font-size: 14px;
        margin-top: 14px;
        margin-bottom: 0px;

        &.required:before {
            content: "*";
            color: var(--danger);
        }
    }

    .form-check {
        .form-check-input{
            height:1rem;
            width: 1rem;
        }
        label {
            margin-top: 0px;
            font-weight: normal;
        }
    }

    .checkbox-container {
        span {
            display: inline-block;
            margin-right: 12px;
            min-width: 100px;
        }
    }

    .lib-checkbox-container {
        label {
            font-weight: 500;
        }
    }

    .lib-checkbox-container {
        color: var(--neutral-70);
        margin: 10px 0px 0px 3px;

        label {
            padding-top: 3px;
        }
    }

    .add-request-button-wrapper {
        display: flex;
        flex-direction: row;

        button {
            margin-top: 10px;
            margin-right: 15px;
        }
    }

    textarea {
        resize: none;
    }

    .request-fac-text {
        min-height: 150px;
    }

    .process-button-col {
        button {
            float: right;
        }
    }

    .close-link-container {
        padding-top: 5px;
    }

    .edit-selection-item {
        font-size: 0.875em;
        color: var(--gray-700);
        cursor: pointer;
        &:hover {
            color: var(--dark-teal);
        }
        position: absolute;
        top: 5px;
        right: 5px;
        display: none;
    }

    .edit-selection-item-nohov {
        margin-top: -5px;
        margin-left: 10px;
        width: 30px;
    }

    .actions-bar{
        padding: 0 8px;
        .col-link {
            margin-top: auto;
            margin-bottom: 6px;
        }
        .action-buttons button {
            margin-left: 8px;
        }
    }
}

.definition-tooltip {
    max-width: 70%;
    .tooltip-inner{
        max-width: 100%;
    }
}
