.library-container {

    .step-container {
        display: flex;
    }

    .library-nav {
        font-size: 18px;
        color: #3D4548;
        font-weight: $form-label-font-weight;

        .nav-link {
            cursor: pointer;
        }

        .active {
            border-bottom: 8px solid #044154;
        }
    }

    .library-type-nav {
        font-size: 16px;
        color: #4D5758;
        font-weight: $form-label-font-weight;
        .nav-link {
            cursor: pointer;
            border-top: 0px;
        }
        .active {
            border-bottom: 8px solid #B3B7B7;
        }
    }

    .type-content{
        h1 {
            font-weight: 500;  
        }
        .nav-link {
            border-top: 0px;
        }
    }

    .nav-border {
        border-bottom: 4px solid #DDE3E4;
        position: relative;
        bottom: 4px;
        z-index: -1;
    }

    .library-objection {
        font-size: 16px;
        line-height: 32px;
        cursor: pointer;
        padding-left: 15px;
    }

    .library-objection-selected {
        font-weight: bold;
        background-color: #DDE3E4;
    }

    .input-group-text {
        font-size: 20px;
    }

    .defense-search-input {
        height: 32px;
    }

    .add-new-button {
        text-align: end;
    }

    .table-edit-item {
        font-size: 15px;
        margin-right: 10px;
        color: $secondary;
        cursor: pointer;

        &:hover {
            color: $primary;
        }
    }

    .table-header {
        border-bottom: 1px solid #cccccc;
    }

    .table-header-cell {
        padding-bottom: 10px;
        padding-left: 15px;
    }

    .table-row {
        border-bottom: 1px solid #cccccc;

        &:hover {
            background-color: #F1F3F3;
        }
    }

    .table-cell {
        padding: 15px;
    }

    .actions-cell {
        text-align: end;
    }

    .objection-type-select {
        max-width: 500px;
    }

    .delete-button {
        color: $danger;
    }

    .bread-crumbs {
        font-weight: 500;
        font-size: 14px;
    }

    .tag-pill {
        background-color: #E6E7E7;
        border-radius: 4px;
        padding: 2.5px 8px;
        margin-right: 8px;
    }

    .question-item{
        border-top:1px solid #E6E7E7;
        padding-left:8px;
        max-width: 700px;
        position: relative;
        .remove-item{
            position: absolute;
            top: 6px;
            right: 6px;
            cursor: pointer;
        }

        .undo-button{
            padding:0px;
            border: 0px;
            margin-bottom: 5px;
        }

        .question-description {
            font-size: smaller;
            margin-bottom: 8px;
        }
    }
  
    .content-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 700px;
      margin: 0px 0px;
    }

    .content-wrapper>label {
        padding-top: 13px;
    }

    .insert-button {
        float: right;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-bottom: 8px;
    }

    .title-group {
        margin-top: 15px;
    }

    .definitions-container{
        label{
            font-weight: 500;
        }
    }

    .info-link{
        font-size: 16px;
        vertical-align: top;
    }
}
