.wizard-content {
    flex: 1;
    .step-container {
        height: 100%;
    }
}

.wizard-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.wizard {
    flex: 1;
}

.step-button {
    $step-action-color: $primary-2;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    cursor: unset !important;
    background-color: $white;
    @include flex-center;
    color: $text;
    border-color: $primary-2;
    &.started {
        @include button-outline-variant($step-action-color);
        color: $text;
    }
    // &.complete {
    //     @include button-outline-variant($step-action-color);
    // }
    &.active {
        @include button-outline-variant($step-action-color);
        background-color: #038474;
        color: white;
        // border-color: $white;
        box-shadow: 0 0 0 $btn-focus-width rgba($step-action-color, 0.5);
    }
}

svg.step-complete-check {
    position: absolute;
    bottom: -21px;
    left: 6px;
    font-size: 22px;
    color: $success;
    &.review {
        bottom: -18px;
    }
}

#case-client-confirm {
    .text-center.flex {
        display: flex;
        justify-content: center;
    }
    .venue-header {
      margin-bottom: 0.625rem;
    }
}
