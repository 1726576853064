@import "../../css/variables";

.jurisdiction-select-container {
    width: 684px;
    border: 1px solid $gray-300;
    padding: 24px 0px;
    margin-top: 32px;

    .mb-8{
        margin-bottom: 8px;
    }

    hr {
        display: flex;
    }

    .jurisdiction-select {
        width: 400px;
        padding: 0px 88px 8px;
        border-bottom: 1px solid $gray-300;
        margin-bottom: 16px;

        .form-check {
            text-align: left;
        }
    }
    
    .state-select {
        text-align: left;
        label {
            font-weight: 500;
        }
        select {
            width: 216px;
        }
    }

    .not-supported{
        padding: 0px 42px;
        h3 {
            font-size: 20px;
            font-weight: 500;
        }

        p {
        text-align: left;
        margin-bottom: 8px;
            &.highlight{
                font-weight: 500;
                font-size: 18px;
            }
        }

        ul {
            padding-left: 24px;
            li{
                text-align: left;
            }
        }
}
}