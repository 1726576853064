@import './../../css/variables';

.propoundSelection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 25px;
  h2 {
    text-align: center;
  }
  .propoundSelectionItem {
    align-items: center;
    border: 1px solid $neutral-30;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    gap: 4px;
    padding: 20px 30px;
    svg {
      height: 28px;
      width: 100%;
      &.edit {
        height: 22px;
        margin-right: 6px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 0;
    }
    &:hover {
      border: 2px solid $primary-2;
      padding: 19px 29px;
    }
  }
}
