.expiry-message-container {
    display: flex;
    flex-direction: row;
}

.banner-wrapper>div {
    color: var(--neutral-70);
    background-color: var(--feedback-info-light) !important;
    border-left: none !important;
}

.restore-wrapper {
    margin-top: -1px;
    margin-right: 5px;

}

.time-box {
    min-width: 24px;
    border: 1px solid var(--neutral-30);
    border-radius: 4px;
    margin: 0px 5px;
    background-color: white;
    padding-left: 6px;
    padding-top: -1px;
}
