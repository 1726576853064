h1{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
h2{
    margin-top: 1rem;
    margin-bottom: 1rem;
    &.move-margin-up {
        margin-top: -1rem;
    }
    &.move-margin-down {
        margin-bottom: -1rem;
    }
}

.no-margin-top{
    margin-top: 0px;
}
.no-margin-bottom{
    margin-bottom: 0px;
}

.how-to-container{
    img {
        margin-bottom: 1rem;
        border: 1px solid #808788;
        box-shadow: 4px 4px 2px #808788;
    }
}

.checkboxTable {
  cursor: pointer;
  -webkit-user-select: none; 
  user-select: none; 
  width: 100%;
  .checkboxIndividual {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid;
    width: 100%;
    &.checked {
      background-color: #dcf2f9;
    }
  }
}
