@import '../../css/_variables.scss';

.menubar-container, .tiptap {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.menubar-container {
  background-color: $neutral-5;
  border-radius: 0.375em 0.375em 0 0;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  padding: 0.5625rem;
  border: 1px solid $neutral-50;
}

.editorContainer {
  max-width: 700px;
  &.fw {
    width: 100%;

    .tiptap {
      max-width: none;
    }
  }

  button {
    background: none;
    border: none;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    margin-right: 0.735em;
    padding: 0.25rem;
    &.is-active {
      background: $gray-200;
    }
  }
}

.menuOn {
  .tiptap {
    border-radius: 0 0 0.375rem 0.375rem;
    border-top: none;
    border-left: 1px solid $neutral-50;
    border-right: 1px solid $neutral-50;
    border-bottom: 1px solid $neutral-50;
  }
}

.tiptap {
  border: 1px solid $neutral-50;
  border-radius: 0.375rem;
  padding: 0.375em 1em;
  background-color: white;

  //For placeholder extension
  p.is-editor-empty:first-child::before {
    color: $neutral-50;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  p.is-empty::before {
    color: $neutral-50;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }

  >*+* {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #FAF594;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}
