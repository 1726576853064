.interrogatory-question-header {
    background-color: #F2F3F3;
    padding-left: 7px;
    padding-bottom: 7px;
    padding-right: 7px;
    padding-top: 7px;
    label {
      font-weight: bold;
    }
}

.interrogatory-checkbox {
    &:checked {
        accent-color: #038474;
    }
}

.feedback-header {
    margin-bottom: 1rem;
    font-size: 24px;
}
.client-msg-lbl{
  font-weight: bold;
  margin-top:16px;
  color: #343f40;
}
.circle-button {
    width: 32px;
    height: 32px;
    text-overflow: ellipsis;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 0 auto;
    background-color: white;
    border: 1px solid;
    margin-bottom: 8px;
}

.circle-button-sl {
    @extend .circle-button;
    color: #038474;
    border-color: #038474;
    margin-bottom: 0px;
}

.circle-ck{
    color: #127C3D;
    margin-left: 7px;
}

.interrogatory-response-box {
    border: 1px solid #B3B7B7;
    width: 216px;
    padding-top: 7px;
}

.interrogatory-question-body {
    margin-left: 2.5rem;
    padding-right: 20px;
}
.txt-client-feedback{
    border-radius: 4px;
    border: 1px solid var(--Neutral-Neutral-50, #808788);
    margin-bottom: 16px;
}

textarea {
  margin-top: 5px;;
  &:focus-within {
    outline: 0;
    border: 0;
  }
  &:focus {
    border: 1px solid #82a0aa;
    box-shadow: 0 0 0 0.25rem rgba(4, 65, 84, 0.25);
  }
}

.int-li{
  &.disabled{
    filter: opacity(60%);
  }

  .plain-button.btn{
      margin-top: 8px;
      font-size: 12px;
      padding: 4px 8px;
      &.suggesting{
          cursor: progress;
      }
  }
}