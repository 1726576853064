@import '../../css/variables.scss';

.container {

    #search-box {
        font-size: 1.5rem;
    }

    .input-group-text {
        font-size: 20px;
    }

    .defense-search-input {
        height: 32px;
    }

    .add-new-button {
        text-align: end;
    }

    .table {
        font-size: 12px;

        .btn-sm {
            font-size: 12px;
        }
    }

    .table-edit-item {
        font-size: 15px;
        margin-right: 10px;
        color: $secondary;
        cursor: pointer;

        &:hover {
            color: $primary;
        }
    }

    .table-header {
        border-bottom: 1px solid #cccccc;
    }

    .table-header-cell {
        padding-bottom: 10px;
        padding-left: 15px;
    }

    .table-row {
        border-bottom: 1px solid #cccccc;

        &:hover {
            background-color: #F1F3F3;
        }
    }

    .table-cell {
        padding: 15px;
    }

    .actions-cell {
        text-align: end;
        padding-right: 8px;
    }

    .objection-type-select {
        max-width: 500px;
    }

    .delete-button {
        color: $danger;
    }

    .bread-crumbs {
        font-weight: 500;
        font-size: 14px;
        vertical-align: bottom;
    }

    .client-name-header {
        font-size: 24px;
        padding-right: 24px;
        float: left;
        margin-top: -3px;
    }
}

.page-container-multi {
    h1, h2 {
        font-family: "Helvetica Neue";
        font-style: normal;
        font-weight: 500;
        color: var(--Neutral-Neutral-70, #4D5758);
    }
    
    h1 {
        font-size: 28px;
        line-height: 36px;
    }
    
    h2 {
        font-size: 20px;
        line-height: 26px;
    }
}