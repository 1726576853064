@import '../../css/_variables.scss';

.tagContainer {
  position: relative;
}

.toastContainer {
  position: absolute;
  width: 100%;
}

.toast {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 0.5rem;
  margin-top: 12px;
  padding: 7px 14px;
  box-shadow: 0px 2px 3px 0px $neutral-30;
  position: relative;
  .closeIcon {
    cursor: pointer;
    position: absolute;
    right: 0.7rem;
    color: $neutral-70;
  }
  &.success {
    background-color: $pale-green;
    border: 1px solid $feedback-success-dark;
    svg {
      &.iconType {
        color: $feedback-success-dark;
      }
    }
  }
  &.warning{
    background-color: $feedback-warning-light;
    border: 1px solid $feedback-warning-dark;
    svg {
      &.iconType {
        color: $feedback-warning-dark;
      }
    }
    span {
      color: $dark-teal;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
