.required-select-modal{
    .client-input-row {
        width: 90%;
        float: left;
        margin-bottom: 24px;

        label.padding {
            margin-top: 16px;
        }
        .form-label {
            font-weight:500;
            font-size:medium;
        }

        label.required:before {
            content:"*";
            color:var(--danger);
        }
        .checkbox-container{
            span{
                display: inline-block;
                margin-right: 12px;
                min-width: 100px;
            }
        }
    }

    .note {
        font-size: small;
    }
}