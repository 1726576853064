// @import '../../../node_modules/bootstrap/scss/_functions';
// @import '../../../node_modules/bootstrap/scss/_variables';
// @import '../../../node_modules/bootstrap/scss/_mixins';
@import '../../css/App.scss';

.plan-card {
    background-color: var(--light-info);
    text-align: center;
    border-radius: 4px;
    border: 1px solid var(--line-gray);
    position: relative;
    div.benefit{
        text-align: left;
        padding-left: 13%;
        .check {
            fill: var(--primary-2);
            margin-bottom: 2%;
        }
        .x {
            fill: var(--danger);
            margin-bottom: 2%;
            path {
                stroke-width: 1px;
                stroke: var(--danger);
            }
        }
    }
    .title {
        font-size: 16px;
        color: #343F40;
        text-transform: uppercase;
        overflow:visible;
        white-space: nowrap;
        font-weight: 700;
    }
    .prices{
        display: inline-flex;
        color: $text;
        margin-top:8px;
        margin-bottom: 5px;
        .text-secondary{
            justify-items: left;
            text-align: left;
            white-space: pre-line;
            white-space: pre-wrap;
            margin-bottom: 6px;
        }
        .price-split{
            width:82px;
            float:left;
            justify-items: left;
            text-align: left;
            font-size: 32px;
            font-weight: 700;
            color: #343F40;
        }

        .slash{
            padding: 0;
            border-left: 1px solid #B3B7B7;
            transform: rotate(18deg);
            margin-left: 4px;
            margin-right: 24px;
        }
    }

    .price{
        font-size: 32px;
        font-weight: 700;
        color: #343F40;
        margin-bottom: 31px;
    }

    .text-secondary{
        font-size: 12px;
        font-weight: 400;
        color: $text;
    }

    .interval-switch {
        label {
            display: inline-block;
            font-size: 12px;
            line-height: 24px;
            font-weight: 700;
            color: var(--gray-500);
            &.selected {
                color: var(--primary-2);
            }
        }
        .form-switch{
            display: inline-block;
            vertical-align: bottom;

            .form-check-input {
                background-color: $form-check-input-checked-bg-color;
                border-color: $form-check-input-checked-border-color;

                @if $enable-gradients {
                    background-image: escape-svg($form-switch-checked-bg-image), var(--#{$prefix}gradient);
                  } @else {
                    background-image: escape-svg($form-switch-checked-bg-image);
                  }
              }
        }
        
        
    }
}

.plan-card.highlight {
    border: 1px solid var(--primary-2);
    .callout {
        position: absolute;
        margin-top:-39px;
        right: 10px;
        background-color: var(--primary-2);
        color: var(--white);
        padding: 2px 6px;
        border-radius: 4px;
        text-align: right;
        white-space: nowrap;
        font-weight: bold;
    }
}

.original-price-slashed {
    position: relative;
}
.original-price-slashed:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 2px solid red;
    transform: rotate(-10deg);
}
.original-price-slashed del {
    text-decoration: none;
}

.result-message {
    line-height: 22px;
    font-size: 16px;
}

.result-message a {
    // replace this with theme color variable
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
}

.hidden {
    display: none;
}

#card-error {
    // replace this with theme color variable
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
}

.plan-discount-length {
    font-size: 10pt;
    margin-bottom: 0px;
}

#payment-request-button {
    margin-bottom: 32px;
}
