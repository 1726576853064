.processing-box {
    margin-bottom: 10px;
    border: 1px solid $border-color-light;
    min-height: 48px;
    display: flex;
    flex-direction: column;
    transition: border-left 250ms;

    &.complete {
        border-left: 9px solid $success;
    }

    &.error {
        border-left: 9px solid $danger;
    }

    &.fixInProgress {
        border-left: 9px solid $warning;
    }

    .complete-icon,
    .error-icon {
        margin-top: 12px;
        width: 1.45rem;
        height: 1.45rem;
        color: $white;
        border-radius: 50%;
        opacity: 0.65;
        font-size: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fixInProgress {
        width: 1.45rem;
        height: 1.45rem;
        color: $warning;
    }

    .progress-pd {
        padding-top: 9px;
    }

    .fixInProgress>svg {
        font-size: 22px;
    }

    .complete-icon {
        background-color: $success;
    }

    .error-icon {
        background-color: $danger;
    }

    .details {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        display: flex;
        flex-direction: row;
        padding: 9px 21px;
        justify-content: space-between;
    }

    .details-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-left: 8px;
    }

    .details-text {
        margin-top: 3px;
        margin-left: 4px;
    }

    .details-text-left {
        margin-top: 4px;
    }

    .header-two-wrap {
        margin-top: 3px;
        margin-left: 4px;
    }

    .startedLink-wrapper {
        margin-right: 3px;
    }

    .loading-bar {
        height: 5px;
        transition: width 1s ease-in-out;
        border-Bottom: 5px solid #038474;
    }
}