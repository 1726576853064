.document-preview-container {
    border: rgb(204, 204, 204) 1px solid;
}

.source-document-preview-height {
    height: 850px;
}

.active-tab:after {
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #337ab7;
    border-radius: 5px;
}

.refresh-banner {
    background: $primary;
    position: absolute;
    width: 100%;
    text-align: end;
}

.document-controls {
    border: #cccccc 1px solid;
}

.button-controls {
    padding: 10px;
    border-top: #cccccc 1px solid;
}

.page-number {
    // line-height: 25px;
    padding-top: 2px;
    font-size: 12px;
    color: #4d4d4d;
    margin-right: 1px;
}

.answer-container {
    position: relative;
    height: 540px;
}

.page-number-button {
    height: 20px;
    width: 20px;
    margin-left: 2px;
    font-size: 10px;
    border-radius: 4px;
    color: var(--black);
    outline: none;
    background-color: var(--white);
}

.defense-options-wrapper {
    border: #cccccc 1px solid;
    border-top: $bright-green 5px solid;
}

.added-defense-list {
    flex: 0 0 auto;
    overflow: scroll;
    max-height: 581px;
    border: #cccccc 1px solid;
}

.display-full-button {
    text-decoration: underline;
    cursor: pointer;
}

.empty-list-label {
    font-size: 12px;
    color: #333333;
}

.defense-search {
    height: 25px;
}

.title-input {
    margin-left: 25px;
    margin-bottom: 10px;
}

.input-group-text {
    border-right: none;
    background-color: white;
    border-radius: 0.25rem 0rem 0rem 0.25rem;
    padding: 0px;
    padding-left: 10px;
    color: #808080;
}


.defense-list {
    height: 100%;
    overflow: scroll;
    max-height: 447px;
    border-top: #cccccc 1px solid;
}

.affirmative-defences-text {
    font-size: 12px;
}

.defense-list-dropdown {
    color: #333333;
    font-size: 12px;
}

.defense-list-padding {
    padding-left: 25px;
    padding-right: 25px;
}

.defence-checkboxes {
    text-align: left;
}

.filter-dropdown {
    font-size: 12px;
}

.remove-icon {
    cursor: pointer;
    font-size: 19px;
    margin-top: 3px;

    &:hover {
        color: $primary;
    }
}

.defense-variants {
    margin: 4px 0 8px 19px;
    display: flex;
    align-items: center;

    .select {
        flex: 1;
    }
}