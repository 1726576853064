// BREAK POINT MIXINS
@mixin sm {
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
        @content;
    }
}

@mixin md {
    @media (min-width: map-get($grid-breakpoints, 'md')) {
        @content;
    }
}

@mixin lg {
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
        @content;
    }
}

@mixin xl {
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
        @content;
    }
}

@mixin max($arg) {
    @media (max-width: $arg) {
        @content;
    }
}

@mixin min($arg) {
    @media (min-width: $arg) {
        @content;
    }
}

@mixin flex-center() {
    display: flex;
    justify-content: center;
    align-items: center;
}
