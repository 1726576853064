.modal-backdrop {
    background-color: $white;
    &.show {
        opacity: 0.8;
    }
}

.modal {
    .modal-header {
        padding: rem(27) rem(40) rem(8);
        h4, .h4 {
            font-size: 24px;
            font-weight: 500;
        }
    }
    .modal-body {
        padding: rem(8) rem(40) rem(8);
    }
    .modal-footer {
        padding: rem(8) rem(40) rem(27);
    }
    .modal-content {
        border-color: $border-color;
        border-top-width: 1px;
        border-top-color: $border-color;
        .modal-header {
            border-bottom: none;
        }
        .modal-footer {
            border-top: none;
        }
    }
}

.modal.hide-close{
    .btn-close{
        display: none;
    }
}

.modal .carousel.tutorial {
    text-align: center;
    font-size: 18px;
    h3 {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    img{
        box-shadow: 0px 2px 8px $border-color;
        vertical-align: middle;
        align-self: center;
        margin: auto;
        margin-bottom: 8px;
        margin-top: 28px;
    }
    img.roundedimg {
        border-radius: 8px;
    }
    ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        list-style: inside;
        padding:0;
        font-size: 14px;
        li{
            text-align:left;
        }
    }
    .carousel-item{
        height: 415px;
    }
}

.modal .suggestBetaModal {
    font-size: small;
    .beta-pill {
        color: white;
        font-weight: bold;
        background-color: #BE328E;
        border-radius: 8px;
        padding: 6px 12px;
    }
    h1{
        margin-top: 12px;
    }
    .video {
        width: 100%;
        margin: auto;
        text-align: center;
    }
}
