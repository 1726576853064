.wizard-document-review {
    min-height: 70vh;
    display: flex;
    &__navigation {
        &-header {
            border-top: 5px solid $success;
        }
        .steps {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 0 8px;
            .separator {
                grid-column: 1/-1;
                margin-right: -1.5rem;
                margin-left: -1.5rem;
            }
        }
    }
}
