.tooltip-row {
    text-align: start;
    font-size: 14px;
}

.tooltip-inner {
    max-width: 300px;
    text-align: start;
}

.externalModal {
  .modal-dialog {
    max-width: 750px;
  }
}

.document-actions {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.quickstart-wrapper {
  .quickstart-container {
    background-color: $neutral-5;
    display: flex;
    gap: 1.325em;
    padding: 1.375em 2.0625em;
    min-width: fit-content;
    div {
      a {
        text-decoration: none;
        display: contents;
      }
      svg {
        height: 77px;
      }
      p {
        font-weight: 500;
        margin: 0;
      }
      &:hover {
        border: 2px solid $primary-2;
      }
      align-items: center;
      background: white;
      border: 1px solid $neutral-50;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      height: 146px;
      justify-content: center;
      width: 192px;
      &.new-feature {
        border: 1px solid #BE328E;
        position: relative;
        &:hover {
          border: 2px solid $primary-2;
          span {
            top: -13px;
            right: 29px;
          }
        }
        span {
          background-color: #BE328E;
          border-radius: 2px;
          color: white;
          font-size: 12px;
          font-weight: 700;
          padding: 4px 8px;
          position: absolute;
          top: -12px;
          right: 30px;
        }
      }
    }
  }
}
