$Feedback-Alert-Dark: #BC3F34;
$Feedback-Alert-Light: #FFF0EF;

.interrogatory-cb {
    height: 25px;
    transform: scale(1.5);
    margin-Right: 10px;
    margin-Left: 0px;
}
.interrogatory-div {
    align-Items: flex-start;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: row;
}
.action-required{
    border-radius: 8px;
    border: 1px solid $Feedback-Alert-Dark;
    border-left: 8px solid $Feedback-Alert-Dark;
    background: $Feedback-Alert-Light; 
    padding: 8px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
}
.flat-bottem{
    border-radius: 8px 8px 0px 0px; 
    padding-bottom: 0px;
}
.action-border{
    border-radius: 8px;
    border: 1px solid $Feedback-Alert-Dark;
    margin-bottom: 8px;
}
.action-text{
    margin-left: 8px;
    margin-bottom: 0px;
}
.alert-icon{
    color: $Feedback-Alert-Dark;
    width: 24px;
    height: 24px; 
    min-width: 24px;
    min-height: 24px; 
}
.frogs-list{
    padding: 0px;
    padding-right: 20px;
}
.frogs-btn{
    display: inline-flex;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.frogs-bounds{
    max-height: 800px;
    display: flex;
    flex-direction: column;
}
.sp-link{
    color: #044154;
    cursor: pointer;
}
.sp-click{
    user-select: none;
    cursor: pointer;
}
