@import '../../css/variables.scss';

.fileWindowContainer {
  padding-top: 0.5em;
  padding-left: 2em;
  padding-right: 2em;
  h2 {
    margin-bottom: 0;
  }
  p {
    &.fileCase {
      font-size: 1em;
    }
  }
}

.fileWindow {
  border: 1px solid;
  display: flex;
  padding: 0.625em 1em;
  justify-content: center;
  .fileTree {
    width: 100%;
  }
}

.fileDirectory {
  :global {
    .ant-tree-treenode-selected .ant-tree-switcher, .ant-tree-node-selected {
      color: black !important;
    }
    .ant-tree-treenode-selected, .ant-tree.ant-tree-directory .ant-tree-treenode {
      &:before {
        background: $pale-green-2 !important;
      }
    }
    .ant-tree-indent-unit.ant-tree-indent-unit, .ant-tree-switcher-leaf-line {
      &:before {
        border-color: gray;
      }
      &:after {
        border-color: gray;
      }
    }
    .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
      width: 0;
      height: 0;
    }
  }
}

.fileWindowBtnWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 1.5em;
  .uploadActions {
    align-items: center;
    display: flex;
    gap: 0.5em;
    a {
      cursor: pointer;
    }
  }
}

.externalFolder {
  border-bottom: 1px solid;
}
