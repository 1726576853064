.popper-filter-dropdown {
    border-radius: 4px;
    border: 1px solid var(--neutral-neutral-30, #B3B7B7);
    background: var(--neutral-white, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
    padding: 10px;
    z-index: 100;
}

.popper-btn-custom {
    max-width: 20em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.btn-dropdown-cl {
    border-radius: 20px;
    border: 1px solid var(--primary-primary-1, #044154);
    background: var(--neutral-white, #FFF);
    color: black;
    gap: 10px;
}

.clear-btn {
    border: none;
    background: none;
    color: var(--primary-primary-1, #044154);
    font-family: Helvetica Neue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}