.button-container {
    margin-top: 24px;
}

.calendar-button {
    margin-right: 12px;
    .button-content-container {
        display: flex;
        align-items: center;
        img {
            margin-right: 4px;
        }
    }
    &:hover img {
        filter: invert(1);
        // to match button hover transition https://getbootstrap.com/docs/5.0/components/buttons/#variables
        transition: filter .15s ease-in-out;
    }
}
