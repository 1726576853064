@use 'sass:math';

// __________________________________________
// COLOR PALETTE
// ------------------------------------------
$ocean-foam: #d6eae4;
$pale-green: #E6F8F1;
$bright-green: #9ccfc8;
$light-teal: #DBF0F3;
$dark-teal: #044154;
$dark-green: #204042;
$pale-green: #D8EDD0;
$pale-green-2: #D6EAE4;
$primary-2: #038474;
$neutral-5: #F2F3F3;
$neutral-30: #B3B7B7;
$neutral-50: #808788;
$neutral-70: #4D5758;
$neutral-80: #343F40;

$gray-50: #F2F3F3;
$gray-100: #E6E7E7;
$gray-200: #CCCFCF;
$gray-300: #B3B7B7;
$gray-500: #808788;
$gray-700: shade-color($gray-500, 30%);

$yellow: #cea552;
$pale-yellow: #FFE2B7;
$red: #bc3f34;
$feedback-info-light: #EDFBFF;
$feedback-success-dark: #127C3D;
$feedback-warning-light: #FFF8EA;
$feedback-warning-dark: #CEA552;

$black: #042628;
$text: #4d5758;
$white: #ffffff;

$line-gray: $gray-500;

// __________________________________________
// SET BOOTSTRAP THEME COLORS
// ------------------------------------------
$primary: $dark-teal;
$secondary: $gray-700;
$secondary-2: $pale-green-2;
$success: $primary-2;
$danger: $red;
$warning: $yellow;
$light: $gray-100;
$dark: $black;
$muted: $gray-200;
$white: $white;
$info: $bright-green;
$navbar-dark-color: $white;
$ocean-foam: $ocean-foam;


$primary-light: tint-color($primary, 70%);

// __________________________________________
// SET IN ROOT FOR CONVENIENCE
// ------------------------------------------
:root {
    --bright-green: #{$bright-green};
    --black: #{$black};
    --white: #{$white};
    --dark-teal: #{$dark-teal};
    --feedback-info-light: #{$feedback-info-light};
    --gray-100: #{$gray-100};
    --gray-200: #{$gray-200};
    --gray-300: #{$gray-300};
    --gray-500: #{$gray-500};
    --gray-700: #{$gray-700};
    --line-gray: #{$line-gray};
    --light-blue: #{$primary-light};
    --neutral-5: #{$neutral-5};
    --neutral-30: #{$neutral-30};
    --neutral-70: #{$neutral-70};
    --primary-2: #{$primary-2};
    --danger: #{$red};
    --warning: #{$warning};
    --text: #{$text};
    --ocean-foam: #{$ocean-foam};
}

$golden-ratio: 1.618;
$font-size-base: 1rem; // 16px
$root-font-size: 16;
$lead-font-weight: normal;

$body-color: $text;

@function rem($pixels, $context: $root-font-size) {
    @return math.div($pixels, $context) * 1rem;
}

@function em($pixels, $context: $root-font-size) {
    @return math.div($pixels, $context) * 1em;
}

$headings-font-weight: 400;
$h1-font-size: $font-size-base * 1.75; //28px
$h2-font-size: $font-size-base * 1.375; //22px
$h3-font-size: $font-size-base * 1.25; //20px
$h4-font-size: $font-size-base * 1.125; //18px
$h5-font-size: $font-size-base * 1; // 16px, same size as base
$h6-font-size: $font-size-base * 0.875; // 14px, same size as <small>
$h7-font-size: $font-size-base * 0.75; //12px

// High values = perfect pill
$btn-border-radius: 100rem;
$btn-border-radius-sm: 100rem;
$btn-padding-x: rem(15);

$btn-font-weight: 500;

$form-check-input-checked-bg-color: $success;

$form-label-font-size: rem(14);
$form-label-font-weight: 500;
$form-label-margin-bottom: 0.25rem;

$input-border-color: $line-gray;
$border-color: $line-gray;
$border-color-light: $gray-200;

$modal-content-border-radius: 0;
$enable-negative-margins: true;
